module.exports = {
  'wunschgutschein-de': {
    name: 'Wunschgutschein',
    brand: 'WUNSCHGUTSCHEIN',
    logo: 'wunschgutschein',
    numberOfShops: 500,
    country: 'DE',
    languages: ['de'],
    amazonPay: {
      language: 'de_DE',
      ledgerCurrency: 'EUR',
    },
    domains: [
      'app.wunschgutschein.de',
      'app.wunschgutschein.de.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.wunschgutschein.de',
    support: {
      url: 'https://www.wunschgutschein.de/pages/hilfe-und-kontakt',
      email: 'hilfe@wunschgutschein.de',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://www.wunschgutschein.de/',
      buy: [
        {
          link: 'https://www.wunschgutschein.de/collections/all',
          label: 'wgs.std.BuyPrivatCustomerOrders',
        },
        {
          link: 'https://b2b.wunschgutschein.de/collections/fuer-kunden',
          label: 'wgs.std.BuyBusinessOrders',
        },
      ],
      charge: true,
    },
    footerLinks: [
      {
        title: 'wgs.std.footerDisclaimer',
        href: 'https://www.wunschgutschein.de/policies/terms-of-service',
      },
      {
        title: 'wgs.std.career',
        href: 'https://www.wunschgutschein.de/pages/karriere',
      },
      {
        title: 'app.einloesebedingungen.headlineEinloesebedingungen',
        href: 'https://www.wunschgutschein.de/pages/einloesebedingungen',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://www.wunschgutschein.de/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://www.wunschgutschein.de/policies/legal-notice',
      },
    ],
    externalRatingButtons: [
      {
        title: 'Trustpilot',
        href: 'https://de.trustpilot.com/review/wunschgutschein.de',
      },
    ],
    voucherCategory: 1,
    cookieFirstSiteKey: '4c5c244a-d3b0-4e3b-bab7-8a3c2379d4d9',
    gtmId: 'GTM-MKXCW94',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showMskPromo',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showChargeableVoucherOrder: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: true,
    showShopPromotion: true,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: true,
    showInfoAfterRedeemButton: true,
    showShopNameOnShopWall: false,
  },
  'wunschgutschein-at': {
    name: 'Wunschgutschein',
    brand: 'WUNSCHGUTSCHEIN',
    logo: 'wunschgutschein',
    numberOfShops: 100,
    country: 'AT',
    languages: ['de'],
    amazonPay: {
      language: 'de_DE',
      ledgerCurrency: 'EUR',
    },
    domains: [
      'app.wunschgutschein.at',
      'app.wunschgutschein.at.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.wunschgutschein.at',
    support: {
      url: 'https://www.wunschgutschein.at/kundenservice/kontakt',
      email: 'info@wunschgutschein.at',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://shop.wunschgutschein.at/',
      buy: [
        {
          link: 'https://shop.wunschgutschein.at/collections/alle-gutscheinmotive',
          label: 'wgs.std.BuyPrivatCustomerOrders',
        },
        {
          link: 'https://shop.wunschgutschein.at/pages/b2b',
          label: 'wgs.std.BuyBusinessOrders',
        },
      ],
      charge: true,
    },
    footerLinks: [
      {
        title: 'wgs.std.einschraenkungen',
        href: 'https://www.wunschgutschein.at/amazon-disclaimer',
      },
      {
        title: 'wgs.std.AGB',
        href: 'https://shop.wunschgutschein.at/policies/terms-of-service',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://shop.wunschgutschein.at/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://shop.wunschgutschein.at/policies/legal-notice',
      },
    ],
    externalRatingButtons: [
      {
        title: 'Trustpilot',
        href: 'https://at.trustpilot.com/review/wunschgutschein.at',
      },
    ],
    voucherCategory: 2,
    cookieFirstSiteKey: 'c2dbb79e-1b53-44b6-b73f-ae2b3d1329a3',
    gtmId: 'GTM-56D4QVP',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showVoucherInfoModal',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: true,
    showInfoAfterRedeemButton: true,
    showShopNameOnShopWall: false,
  },
  'wishcard-ch': {
    name: 'Wishcard',
    brand: 'WISHCARD',
    logo: 'wishcard',
    numberOfShops: 100,
    country: 'CH',
    languages: ['de', 'en', 'fr', 'it'],
    amazonPay: {
      language: null,
      ledgerCurrency: 'EUR',
    },
    domains: [
      'app.wishcard.ch',
      'app.wishcard.ch.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.wishcard.ch',
    support: {
      url: 'https://www.wishcard.ch/kundenservice/kontakt',
      email: 'info@wishcard.ch',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://shop.wishcard.ch/',
      buy: [
        {
          link: 'https://shop.wishcard.ch/collections/alle-gutscheinmotive-de',
          label: 'wgs.std.BuyPrivatCustomerOrders',
        },
      ],
      charge: true,
    },
    footerLinks: [
      {
        title: 'wgs.std.einschraenkungen',
        href: 'https://www.wishcard.ch/amazon-disclaimer',
      },
      {
        title: 'wgs.std.AGB',
        href: 'https://shop.wishcard.ch/policies/terms-of-service',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://shop.wishcard.ch/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://shop.wishcard.ch/policies/legal-notice',
      },
    ],
    voucherCategory: 9,
    cookieFirstSiteKey: 'd4816293-c28e-41f3-84b1-b24eae41ccd9',
    gtmId: 'GTM-PWGJJHV',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showVoucherInfoModal',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: false,
  },
  'wishcard-it': {
    name: 'Wishcard',
    brand: 'WISHCARD',
    logo: 'wishcard',
    numberOfShops: 50,
    country: 'IT',
    languages: ['it'],
    amazonPay: {
      language: 'it_IT',
      ledgerCurrency: 'EUR',
    },
    domains: [
      'app.wishcard.it',
      'app.wishcard.it.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.wishcard.it',
    support: {
      url: 'https://www.wishcard.it/kundenservice/kontakt',
      email: 'info@wishcard.it',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://shop.wishcard.it/',
      buy: [
        {
          link: 'https://shop.wishcard.it/collections/panoramica-dei-motivi',
          label: 'wgs.std.BuyPrivatCustomerOrders',
        },
        {
          link: 'https://shop.wishcard.it/pages/il-regalo-perfetto-per-collaboratori-e-clienti',
          label: 'wgs.std.BuyBusinessOrders',
        },
      ],
    },
    footerLinks: [
      {
        title: 'wgs.std.einschraenkungen',
        href: 'https://www.wishcard.it/amazon-disclaimer',
      },
      {
        title: 'wgs.std.AGB',
        href: 'https://shop.wishcard.it/policies/terms-of-service',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://shop.wishcard.it/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://shop.wishcard.it/policies/legal-notice',
      },
    ],
    voucherCategory: 15,
    cookieFirstSiteKey: 'cb2be523-91be-4181-bfc8-320d1206e235',
    gtmId: 'GTM-WW5BZDX',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showVoucherInfoModal',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: false,
    showRechargeCheckbox: false,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: false,
  },
  'carteplaisir-fr': {
    name: 'Carte Plaisir',
    brand: 'CARTEPLAISIR',
    logo: 'carteplaisir',
    numberOfShops: 150,
    country: 'FR',
    languages: ['fr'],
    amazonPay: {
      language: 'fr_FR',
      ledgerCurrency: 'EUR',
    },
    domains: [
      'app.carteplaisir.fr',
      'app.carteplaisir.fr.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.carteplaisir.fr',
    support: {
      url: 'https://www.carteplaisir.fr/kundenservice/kontakt',
      email: 'info@everywish.fr',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://shop.carteplaisir.fr',
      buy: [
        {
          link: 'https://shop.carteplaisir.fr/collections/frontpage',
          label: 'wgs.std.buttonGutscheinKaufen',
        },
      ],
    },
    footerLinks: [
      {
        title: 'wgs.std.einschraenkungen',
        href: 'https://www.carteplaisir.fr/amazon-disclaimer',
      },
      {
        title: 'wgs.std.AGB',
        href: 'https://shop.carteplaisir.fr/policies/terms-of-service',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://shop.carteplaisir.fr/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://shop.carteplaisir.fr/policies/legal-notice',
      },
    ],
    voucherCategory: 20,
    cookieFirstSiteKey: '3f677e6d-0a77-4d91-a381-3956ecff183e',
    gtmId: 'GTM-KH68MPN',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showVoucherInfoModal',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: false,
  },
  'everywish-gb': {
    name: 'Everywish',
    brand: 'EVERYWISH',
    logo: 'everywish',
    numberOfShops: 250,
    country: 'GB',
    languages: ['en'],
    amazonPay: {
      language: 'en_GB',
      ledgerCurrency: 'GBP',
    },
    domains: [
      'app.every-wish.co.uk',
      'app.every-wish.co.uk.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.every-wish.co.uk',
    support: {
      url: 'https://www.every-wish.co.uk/pages/contact',
      email: 'info@every-wish.co.uk',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://www.every-wish.co.uk/',
      buy: [
        {
          link: 'https://www.every-wish.co.uk/collections/all',
          label: 'wgs.std.buttonGutscheinKaufen',
        },
      ],
    },
    footerLinks: [
      {
        title: 'wgs.std.einschraenkungen',
        href: 'https://www.every-wish.co.uk/amazon-disclaimer',
      },
      {
        title: 'wgs.std.AGB',
        href: 'https://www.every-wish.co.uk/policies/terms-of-service',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://www.every-wish.co.uk/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://www.every-wish.co.uk/policies/legal-notice',
      },
    ],
    externalRatingButtons: [
      {
        title: 'Trustpilot',
        href: 'https://uk.trustpilot.com/review/every-wish.co.uk',
      },
    ],
    voucherCategory: 27,
    cookieFirstSiteKey: 'f0ad1028-2716-4e8a-9b2e-625449295341',
    gtmId: 'GTM-KC43PLP',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showVoucherInfoModal',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: true,
  },
  'everywish-be': {
    name: 'Everywish',
    brand: 'EVERYWISH',
    logo: 'everywish',
    numberOfShops: 100,
    country: 'BE',
    languages: ['fr', 'nl', 'en'],
    amazonPay: {
      language: 'fr_BE',
      ledgerCurrency: 'EUR',
    },
    domains: [
      'app.everywish.be',
      'app.everywish.be.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.everywish.fr',
    support: {
      url: 'https://www.everywish.fr/kundenservice/kontakt',
      email: 'info@everywish.fr',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://www.everywish.fr',
      buy: [
        {
          link: 'https://www.everywish.fr/collections/frontpage',
          label: 'wgs.std.BuyPrivatCustomerOrders',
        },
      ],
    },
    footerLinks: [
      {
        title: 'wgs.std.einschraenkungen',
        href: 'https://www.everywish.fr/amazon-disclaimer',
      },
      {
        title: 'wgs.std.AGB',
        href: 'https://www.everywish.fr/policies/terms-of-service',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://www.everywish.fr/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://www.everywish.fr/policies/legal-notice',
      },
    ],
    voucherCategory: 34,
    cookieFirstSiteKey: 'f0ad1028-2716-4e8a-9b2e-625449295341',
    gtmId: 'GTM-KC43PLP',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showVoucherInfoModal',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: false,
  },
  nissan: {
    name: 'Nissan Financial Services',
    brand: 'Gutschein',
    logo: 'nissan',
    faviconPath: 'nissan',
    externalCssFile: '/custom/css/nissan.css',
    keyVisualImage: 'sites/nissan/keyvisual_de.gif',
    redeemCampaign: 'nissan',
    numberOfShops: 500,
    country: 'DE',
    languages: ['de'],
    domains: [
      'getmore.nissanbank-onlineprovision.de',
      'getmore.nissanbank-onlineprovision.de.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    distribution: 'B2B_ORELUNITED_POR',
    website: 'https://www.nissanbank-onlineprovision.de/',
    support: {
      url: 'https://www.nissanbank-onlineprovision.de/kontakt',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    footerLinks: [
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://www.nissanbank-onlineprovision.de/impressum.php',
      },
    ],
    voucherCategory: 1,
    cookieFirstSiteKey: 'ddd0ac11-8f92-4858-b7e2-1d7c4609e39c',
    gtmId: 'GTM-MKXCW94',
    brandingColor: '#000000',
    heading: 'NISSAN FINANCIAL SERVICES - GETMORE-XL',
    flags: [
      'showShopFilterBar',
    ],
    copyright: 'Nissan Financial Services',
    /* html */
    text: `
      <ol>
        <li>
          <strong>Geben Sie hier Ihren Code ein.</strong><br>
          Der Code befindet sich in Ihrer persönlichen E-Mail.
        </li>
        <li>
          <strong>Wählen Sie Ihre Prämie aus über 200 Partner-Shops aus.</strong><br>
          Bei Auswahl der gewünschten Prämie wird angezeigt, ob die Einlösung
          online und/oder in den dazugehörigen Filialen möglich ist.
        </li>
      </ol>
    `,
    /* html */
    thankYouText: `
      <p>Der Gutscheincode für den von Ihnen gewählten Shop wird Ihnen an
      Ihre E-Mail-Adresse gesendet. Sollten Sie keine E-Mail in Ihrem
      Postfach vorfinden, Überprüfen Sie bitte auch Ihren SPAM-Ordner.</p>
      <p>Allerdings kann sich bei hoher Auslastung der Versand
      zeitlich verzögern.</p>
      <p>Viel Spaß mit Ihrer Prämie wünscht Ihnen Ihr GETMORE-XL Team!</p>
    `,
    showApiBanner: false,
    showBrandInVault: false,
    showChargeableVoucherOrder: false,
    showCustomerInquiry: false,
    showCustomerSurvey: false,
    showGiveaway: false,
    showHowToRedeem: false,
    showKeyVisualText: false,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: false,
    showVariation: false,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: false,
  },
  renault: {
    name: 'Mobilize Financial Services',
    brand: 'Gutschein',
    logo: 'mobilize',
    faviconPath: 'renault',
    externalCssFile: '/custom/css/renault.css',
    keyVisualImage: 'sites/renault/keyvisual_de.gif',
    redeemCampaign: 'renault',
    numberOfShops: 500,
    country: 'DE',
    languages: ['de'],
    domains: [
      'getmore.renaultbank-onlineprovision.de',
      'getmore.renaultbank-onlineprovision.de.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    distribution: 'B2B_ORELUNITED_POR',
    website: 'https://renaultbank-onlineprovision.de/',
    support: {
      url: 'https://renaultbank-onlineprovision.de/kontakt.php',
      email: 'hotline@renaultbank-onlineprovision.de',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    footerLinks: [
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://renaultbank-onlineprovision.de/impressum.php',
      },
    ],
    voucherCategory: 1,
    cookieFirstSiteKey: '0974f13c-8a16-4e41-b7e4-6666a596a30b',
    gtmId: 'GTM-MKXCW94',
    brandingColor: '#000000',
    heading: 'MOBILIZE FINANCIAL SERVICES - GETMORE-XL',
    flags: [
      'showShopFilterBar',
    ],
    copyright: 'Mobilize Financial Services',
    /* html */
    text: `
      <ol>
        <li>
          <strong>Geben Sie hier Ihren Code ein.</strong><br>
          Der Code befindet sich in Ihrer persönlichen E-Mail.
        </li>
        <li>
          <strong>Wählen Sie Ihre Prämie aus über 200 Partner-Shops aus.</strong><br>
          Bei Auswahl der gewünschten Prämie wird angezeigt, ob die Einlösung
          online und/oder in den dazugehörigen Filialen möglich ist.
        </li>
      </ol>
    `,
    /* html */
    thankYouText: `
      <p>Der Gutscheincode für den von Ihnen gewählten Shop wird Ihnen an
      Ihre E-Mail-Adresse gesendet. Sollten Sie keine E-Mail in Ihrem
      Postfach vorfinden, Überprüfen Sie bitte auch Ihren SPAM-Ordner.</p>
      <p>Allerdings kann sich bei hoher Auslastung der Versand
      zeitlich verzögern.</p>
      <p>Viel Spaß mit Ihrer Prämie wünscht Ihnen Ihr GETMORE-XL Team!</p>
    `,
    showApiBanner: false,
    showBrandInVault: false,
    showChargeableVoucherOrder: false,
    showCustomerInquiry: false,
    showCustomerSurvey: false,
    showGiveaway: false,
    showHowToRedeem: false,
    showKeyVisualText: false,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: false,
    showVariation: false,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: false,
  },
  'everywish-fr': {
    name: 'Everywish',
    brand: 'EVERYWISH',
    logo: 'everywish',
    keyVisualImage: 'sites/everywish/keyvisual_fr.jpg',
    instructionsImage: 'sites/everywish/how-to-redeem_fr.jpg',
    faviconPath: 'everywish',
    numberOfShops: 300,
    country: 'FR',
    languages: ['fr'],
    amazonPay: {
      language: 'fr_FR',
      ledgerCurrency: 'EUR',
    },
    domains: [
      'app.everywish.fr',
      'app.everywish.fr.local',
    ],
    zendesk: {
      isActive: true,
      key: process.env.VUE_APP_ZENDESK_KEY_FR,
    },
    website: 'https://www.everywish.fr',
    support: {
      url: 'https://www.everywish.fr/kundenservice/kontakt',
      email: 'info@everywish.fr',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://www.everywish.fr',
      buy: [
        {
          link: 'https://www.everywish.fr/collections/frontpage',
          label: 'wgs.std.BuyPrivatCustomerOrders',
        },
      ],
    },
    footerLinks: [
      {
        title: 'wgs.std.einschraenkungen',
        href: 'https://www.everywish.fr/amazon-disclaimer',
      },
      {
        title: 'wgs.std.AGB',
        href: 'https://www.everywish.fr/policies/terms-of-service',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://www.everywish.fr/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://www.everywish.fr/policies/legal-notice',
      },
    ],
    voucherCategory: 20,
    cookieFirstSiteKey: '6545627a-7efb-48f8-bb7a-f48552aa2ca8',
    gtmId: 'GTM-KH68MPN',
    distribution: 'Online_FR',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showVoucherInfoModal',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: true,
  },
  mastercard: {
    name: 'Mastercard',
    brand: 'WUNSCHGUTSCHEIN',
    logo: 'mastercard',
    faviconPath: 'mastercard',
    externalCssFile: '/custom/css/mastercard.css',
    keyVisualImage: 'sites/mastercard/keyvisual_de.jpg',
    redeemCampaign: 'mastercard',
    numberOfShops: 500,
    country: 'DE',
    languages: ['de'],
    domains: [
      'mastercard.wunschgutschein.de',
      'mastercard.wunschgutschein.de.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.wunschgutschein.de',
    support: {
      url: 'https://www.wunschgutschein.de/pages/hilfe-und-kontakt',
      email: 'hilfe@wunschgutschein.de',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    footerLinks: [
      {
        title: 'wgs.std.footerDisclaimer',
        href: 'https://www.wunschgutschein.de/policies/terms-of-service',
      },
      {
        title: 'app.einloesebedingungen.headlineEinloesebedingungen',
        href: 'https://www.wunschgutschein.de/pages/einloesebedingungen',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://www.wunschgutschein.de/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://www.wunschgutschein.de/policies/legal-notice',
      },
    ],
    voucherCategory: 1,
    cookieFirstSiteKey: '4c5c244a-d3b0-4e3b-bab7-8a3c2379d4d9',
    gtmId: 'GTM-MKXCW94',
    brandingColor: '#f37439',
    heading: 'Mastercard Wunschgutschein - hier geht\'s zu Ihrer Prämie:',
    flags: [
      'showShopFilterBar',
    ],
    copyright: 'Mastercard',
    showApiBanner: false,
    showBrandInVault: false,
    showChargeableVoucherOrder: false,
    showCustomerInquiry: false,
    showCustomerSurvey: false,
    showGiveaway: false,
    showHowToRedeem: false,
    showKeyVisualText: false,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: false,
    showUseDataConfirmation: false,
    showVariation: false,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: false,
  },
  region: {
    name: 'Regionalgutschein',
    brand: 'Regionalgutschein',
    customMeta: {
      title: 'Der Gutschein für deine Region - Gutschein hier einlösen',
      // eslint-disable-next-line max-len
      description: 'Der Gutschein für deine Region ist deutschlandweit für über 50.000 Geschäfte & Restaurants einlösbar. Entdecke die Vielfalt deiner Regionn',
    },
    layout: 'modern',
    logo: 'wunschgutschein',
    numberOfShops: 250,
    brandingColor: '#2AD1CE',
    externalCssFile: '/custom/css/region.css',
    instructionsImage: 'sites/region/region-voucher.svg',
    heading: false,
    text: false,
    country: 'DE',
    languages: ['de'],
    amazonPay: {
      language: 'de_DE',
      ledgerCurrency: 'EUR',
    },
    domains: [
      'region.wunschgutschein.de',
      'region.wunschgutschein.de.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    // pathnames: [
    //   /^\/region(\/.*|)$/,
    // ],
    website: 'https://www.wunschgutschein.de',
    support: {
      url: 'https://www.wunschgutschein.de/pages/hilfe-und-kontakt',
      email: 'hilfe@wunschgutschein.de',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://www.wunschgutschein.de/',
      buy: [
        {
          link: 'https://www.wunschgutschein.de/pages/der-gutschein-fuer-deine-region',
          label: 'wgs.std.buttonGutscheinKaufen',
        },
      ],
    },
    footerLinks: [
      {
        title: 'wgs.std.footerDisclaimer',
        href: 'https://www.wunschgutschein.de/policies/terms-of-service',
      },
      {
        title: 'wgs.std.contact',
        href: 'https://region.wunschgutschein.de/contact',
      },
      {
        title: 'wgs.std.career',
        href: 'https://www.wunschgutschein.de/pages/karriere',
      },
      {
        title: 'app.einloesebedingungen.headlineEinloesebedingungen',
        href: 'https://www.wunschgutschein.de/pages/einloesebedingungen',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://www.wunschgutschein.de/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://www.wunschgutschein.de/policies/legal-notice',
      },
    ],
    externalRatingButtons: [
      {
        title: 'Trustpilot',
        href: 'https://de.trustpilot.com/review/wunschgutschein.de',
      },
    ],
    voucherCategory: 31,
    cookieFirstSiteKey: '4c5c244a-d3b0-4e3b-bab7-8a3c2379d4d9',
    gtmId: 'GTM-MKXCW94',
    flags: [
      'showNavBar',
    ],
    copyright: 'DER GUTSCHEIN FÜR DEINE REGION ist ein Produkt der Wunschgutschein GmbH.',
    showApiBanner: false,
    showBrandInVault: true,
    showChargeableVoucherOrder: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: false,
    showMerchantCodeRedirect: true,
    showRechargeCheckbox: true,
    showShopPromotion: true,
    showUseDataConfirmation: true,
    showVariation: false,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: true,
    showInfoAfterRedeemButton: true,
    showShopNameOnShopWall: false,
  },
  'everywish-us': {
    name: 'Everywish',
    brand: 'EVERYWISH',
    logo: 'everywish',
    numberOfShops: 400,
    country: 'US',
    languages: ['en'],
    amazonPay: {
      language: 'en_US',
      ledgerCurrency: 'USD',
    },
    domains: [
      'app.every-wish.com',
      'app.every-wish.com.local',
    ],
    zendesk: {
      isActive: false,
      key: '',
    },
    website: 'https://www.every-wish.co.uk',
    support: {
      url: 'https://www.every-wish.co.uk/pages/contact',
      email: 'info@every-wish.co.uk',
    },
    b2bLinks: {
      b2bWebsite: 'https://b2b.wunschgutschein.de/pages/produkte-fuer-unternehmen',
      b2bContactForm: 'https://b2b.wunschgutschein.de/pages/kontaktanfrage',
    },
    navbarLinks: {
      start: 'https://www.every-wish.co.uk/',
      buy: [
        {
          link: 'https://www.every-wish.co.uk/collections/all',
          label: 'wgs.std.buttonGutscheinKaufen',
        },
      ],
    },
    footerLinks: [
      {
        title: 'wgs.std.einschraenkungen',
        href: 'https://www.every-wish.co.uk/amazon-disclaimer',
      },
      {
        title: 'wgs.std.AGB',
        href: 'https://www.every-wish.co.uk/policies/terms-of-service',
      },
      {
        title: 'app.datenschutz.headlineDatenschutzerklaru',
        href: 'https://www.every-wish.co.uk/policies/privacy-policy',
      },
      {
        title: 'app.impressum.headlineImpressum',
        href: 'https://www.every-wish.co.uk/policies/legal-notice',
      },
    ],
    externalRatingButtons: [
      {
        title: 'Trustpilot',
        href: 'https://uk.trustpilot.com/review/every-wish.co.uk',
      },
    ],
    voucherCategory: 33,
    cookieFirstSiteKey: 'c7efea73-c00f-4d80-b491-e577f6d386c5',
    gtmId: 'GTM-KC43PLP',
    flags: [
      'showShopFilterBar',
      'showNavBar',
      'showVoucherInfoModal',
    ],
    showApiBanner: true,
    showBrandInVault: true,
    showCustomerInquiry: true,
    showCustomerSurvey: true,
    showGiveaway: true,
    showHowToRedeem: true,
    showKeyVisualText: true,
    showMerchantCodeRedirect: false,
    showRechargeCheckbox: false,
    showUseDataConfirmation: true,
    showVariation: true,
    redeemValueChooseable: true,
    showInfoBeforeRedeemButton: false,
    showInfoAfterRedeemButton: false,
    showShopNameOnShopWall: false,
  },
};
